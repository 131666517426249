import React from 'react'
import {Link} from 'gatsby'
import Flickity from 'react-flickity-component'
import Fade from 'react-reveal/Fade'

import Section from '../../../../components/section/Section'
import StyledLink from '../../../../components/styled-link/StyledLink'
// import StyledBlock from '../../../../components/styled-block/StyledBlock'

import style from './Merch.module.scss'
import '../../../../components/flickity/Flickity.scss'

const Merch = () => {
  const carouselOptions = {
    draggable: false,
    wrapAround: true,
    autoPlay: true,
    prevNextButtons: false,
    pageDots: false
  }

  return (
    <Section className={style.merch}>
      <Fade bottom distance="25%">
        <div className={style.title}>
          <h2>You're doing good<br />put some gear on</h2>
        </div>
      </Fade>
      <div className={style.content}>
        <Fade bottom distance="25%">
          <div className={style.text}>
            <p>Level up your style game and represent the&nbsp;Ming dynasty in the&nbsp;streets with some merch featuring the&nbsp;signature designs.</p>
            <div className={style.links}>
              <Link
                to="/"
                className={style.item}
              >
                <StyledLink disabled>
                  {/* <span>View all</span> */}
                  <span>Coming soon</span>
                </StyledLink>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade bottom distance="25%">
          {/* <StyledBlock className={style.media}>
            <Flickity
              options={carouselOptions}
            >
              <img src="./assets/images/tshirt.jpg" alt="tshirt" />
              <img src="./assets/images/sweater.jpg" alt="sweater" />
              <img src="./assets/images/hat.jpg" alt="hat" />
            </Flickity>
          </StyledBlock> */}
          <div className={style.media}>
            <Flickity
              options={carouselOptions}
            >
              <img src="./assets/images/merch1.jpg" alt="Logo sweatshirt" />
              <img src="./assets/images/merch2.jpg" alt="Noodle guy t-shirt" />
              <img src="./assets/images/merch3.jpg" alt="MrbongW hoodie" />
            </Flickity>
          </div>
        </Fade>
      </div>
    </Section>
  )
}

export default Merch
